import { useState, useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import Topbar from "./scenes/global/Topbar";
import Sidebar from "./scenes/global/Sidebar";
import Dashboard from "./scenes/dashboard";
import Team from "./scenes/team";
import Contacts from "./scenes/contacts";
import Form from "./scenes/form";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { ColorModeContext, useMode } from "./theme";
import Files from "./scenes/files";
import PrivateRoute from "./utils/PrivateRoute";
import Login from "./scenes/Login/Login";
import { useAuth } from "./context/AuthContext";
import Cookies from "js-cookie";

function App() {
  const [theme, colorMode] = useMode();
  const [isSidebar, setIsSidebar] = useState(true);
  const { isAuthenticated, login } = useAuth();

  useEffect(() => {
    // Verificar si la cookie de autenticación está presente al cargar la aplicación
    const isLoggedIn = Cookies.get("isLoggedIn");

    if (isLoggedIn === "true") {
      const isAdmin = JSON.parse(Cookies.get("isAdmin"));
      const userId = JSON.parse(Cookies.get("userId"));
      login(isAdmin, userId);
    }
  }, []);
  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <div className="app">
          {isAuthenticated && <Sidebar isSidebar={isSidebar} />}
          <main
            className="content"
            style={{
              overflowY: "scroll",
              display: "flex",
              flexDirection: "column",
            }}
          >
            {isAuthenticated && <Topbar setIsSidebar={setIsSidebar} />}
            <Routes>
              <Route path="/" element={<Login />} />
              <Route element={<PrivateRoute />}>
                <Route path="/dashboard" element={<Dashboard />} />
                <Route path="/users" element={<Team />} />
                <Route path="/contacts" element={<Contacts />} />
                <Route path="/form" element={<Form />} />
                <Route path="/files" element={<Files />} />
              </Route>
            </Routes>
          </main>
        </div>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
}

export default App;
