import { useState } from "react";
import { Typography, Box, useTheme } from "@mui/material";
import Modal from "@mui/material/Modal";
import { tokens } from "../theme";
import ModalUser from "./ModalUser";
import { API_URL } from "../data/AppConstants";
import { IconButton } from "@mui/material";
import SyncIcon from "@mui/icons-material/Sync";
import { useAuth } from "../context/AuthContext";

const Header = ({
  title,
  subtitle,
  showButton,
  showButton2,
  handleOpenModal2,
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { isMobile } = useAuth();

  // Estado para controlar la apertura/cierre del modal
  const [isModalOpen, setIsModalOpen] = useState(false);

  // Función para abrir el modal
  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  // Función para cerrar el modal
  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleSync = () => {
    fetch(`${API_URL}campaing/sync-logos`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({}),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        if (data.ok) {
          alert(data.message);
        } else {
          console.log(data.error);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: isMobile ? "column" : "row",
        justifyContent: "space-between",
      }}
    >
      <Box mb="30px">
        <Typography
          variant="h2"
          color={colors.grey[100]}
          fontWeight="bold"
          sx={{ m: "0 0 5px 0" }}
        >
          {title}
        </Typography>
        <Typography variant="h5" color={colors.greenAccent[400]}>
          {subtitle}
        </Typography>
      </Box>
      {showButton && (
        <>
          <Box
            width="100px"
            height="40px"
            m="5"
            p="5px"
            display="flex"
            justifyContent="center"
            backgroundColor={colors.greenAccent[600]}
            borderRadius="4px"
            alignSelf="self-end"
            onClick={handleOpenModal}
          >
            <p
              style={{
                color: colors.grey[100],
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                fontSize: "16px",
                cursor: "pointer",
              }}
            >
              Crear
            </p>
          </Box>
          {/* Modal */}
          <Modal open={isModalOpen} onClose={handleCloseModal}>
            <div>
              <ModalUser handleCloseModal={handleCloseModal} />
            </div>
          </Modal>
        </>
      )}
      {showButton2 && (
        <>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              gap: "20px",
            }}
          >
            <IconButton
              style={{
                backgroundColor: "#218838",
                height: "40px",
                borderRadius: "4px",
                // margin: "20px auto",
                // marginBottom: "0",
                display: "flex",
                fontWeight: "semibold",
                justifyContent: "center",
                alignItems: "center",
                alignSelf: "self-end",
                color: "white", // Color del icono
              }}
              onClick={() => handleSync()}
            >
              <SyncIcon /> Sincronizar archivos
            </IconButton>
            <Box
              width="100px"
              height="40px"
              m="5"
              p="5px"
              display="flex"
              justifyContent="center"
              backgroundColor={colors.greenAccent[600]}
              borderRadius="4px"
              alignSelf="self-end"
              onClick={handleOpenModal2}
            >
              <p
                style={{
                  color: colors.grey[100],
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  fontSize: "16px",
                  cursor: "pointer",
                }}
              >
                Crear
              </p>
            </Box>
          </div>
          {/* Modal */}
          <Modal open={isModalOpen} onClose={handleCloseModal}>
            <div>
              <ModalUser handleCloseModal={handleCloseModal} />
            </div>
          </Modal>
        </>
      )}
    </div>
  );
};

export default Header;
