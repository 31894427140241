import React from "react";
import { RingLoader } from "react-spinners";

const loaderCss = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flex: 1,
};

const Loader = () => {
  return (
    <div style={loaderCss}>
      <RingLoader color="#36d7b7" />
    </div>
  );
};

export default Loader;
