import { useState } from "react";
import {
  Typography,
  Box,
  useTheme,
  FormControl,
  InputLabel,
  Input,
  Checkbox,
  FormGroup,
  FormControlLabel,
  Button,
} from "@mui/material";
import { API_URL } from "../data/AppConstants";
import { tokens } from "../theme";

const ModalUser = ({ handleCloseModal, usuario = {} }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const editMode = Object.keys(usuario).length !== 0;
  const [changePassword, setChangePassword] = useState(false);

  const handleChangePassword = () => {
    setChangePassword(true);
  };

  // Estados para capturar los valores del formulario
  const [formValues, setFormValues] = useState({
    UserName: editMode ? usuario.UserName : "",
    Location: editMode ? usuario.Location : "",
    Folder: editMode ? usuario.Folder : "",
    Password: "",
    IsPro: editMode ? (usuario.IsPro === 1 ? true : false) : true,
    IsActive: editMode ? (usuario.IsActive === 1 ? true : false) : true,
  });

  // Función para manejar el cambio en los campos del formulario
  const handleChange = (event) => {
    const { name, value, type, checked } = event.target;
    setFormValues((prevFormValues) => ({
      ...prevFormValues,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handleCreateUser = () => {
    // Verificar si algún campo está vacío
    if (
      formValues.UserName === "" ||
      formValues.Location === "" ||
      formValues.Folder === "" ||
      (formValues.Password === "" && !editMode)
    ) {
      alert("Por favor, complete todos los campos.");
    } else {
      if (editMode) {
        fetch(`${API_URL}user/editUserInfo/${usuario.id}`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json", // Especificamos que el cuerpo es JSON
          },
          body: JSON.stringify(formValues),
        })
          .then((response) => response.json())
          .then((data) => {
            if (data.ok) {
              alert(data.message);
              handleCloseModal();
            } else {
              alert(data.error);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        fetch(`${API_URL}user/createUser`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json", // Especificamos que el cuerpo es JSON
          },
          body: JSON.stringify(formValues),
        })
          .then((response) => response.json())
          .then((data) => {
            if (data.ok) {
              alert(data.message);
              handleCloseModal();
            } else {
              alert(data.error);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    }
  };
  return (
    <Box
      sx={{
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        backgroundColor: colors.primary[800],
        boxShadow: 24,
        p: 4,
        borderRadius: "8px",
        minWidth: "400px",
      }}
    >
      <Typography variant="h4" mb={2}>
        {editMode ? "Editar Usuario" : "Nuevo Usuario"}
      </Typography>
      <FormControl fullWidth sx={{ mb: 2 }}>
        <InputLabel htmlFor="UserName">Usuario</InputLabel>
        <Input
          id="UserName"
          name="UserName"
          value={formValues.UserName}
          onChange={handleChange}
        />
      </FormControl>
      {editMode && !changePassword && (
        <Button
          variant="contained"
          color="secondary"
          onClick={handleChangePassword}
          sx={{ mb: 2 }}
        >
          Cambiar Contraseña
        </Button>
      )}

      {editMode && changePassword && (
        <FormControl fullWidth sx={{ mb: 2 }}>
          <InputLabel htmlFor="Password">Contraseña</InputLabel>
          <Input
            id="Password"
            name="Password"
            type="password"
            value={formValues.Password}
            onChange={handleChange}
          />
        </FormControl>
      )}
      {!editMode && (
        <FormControl fullWidth sx={{ mb: 2 }}>
          <InputLabel htmlFor="Password">Contraseña</InputLabel>
          <Input
            id="Password"
            name="Password"
            type="password"
            value={formValues.Password}
            onChange={handleChange}
          />
        </FormControl>
      )}
      <FormControl fullWidth sx={{ mb: 2 }}>
        <InputLabel htmlFor="Folder">Carpeta</InputLabel>
        <Input
          id="Folder"
          name="Folder"
          value={formValues.Folder}
          onChange={handleChange}
        />
      </FormControl>
      <FormControl fullWidth sx={{ mb: 2 }}>
        <InputLabel htmlFor="Location">Ubicación</InputLabel>
        <Input
          id="Location"
          name="Location"
          value={formValues.Location}
          onChange={handleChange}
        />
      </FormControl>

      <FormGroup>
        <FormControlLabel
          control={
            <Checkbox
              checked={formValues.IsPro}
              onChange={handleChange}
              name="IsPro"
            />
          }
          label="Pro"
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={formValues.IsActive}
              onChange={handleChange}
              name="IsActive"
            />
          }
          label="Activo"
        />
      </FormGroup>
      <Box display="flex" justifyContent="space-around" mt={3}>
        {/* Botón para imprimir los datos */}
        <Button
          variant="contained"
          color="secondary"
          onClick={handleCreateUser}
        >
          Guardar
        </Button>
        {/* Botón para cerrar el modal */}
        <Button variant="contained" onClick={handleCloseModal}>
          Cerrar
        </Button>
      </Box>
    </Box>
  );
};

export default ModalUser;
