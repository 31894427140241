import React, { useState, useEffect } from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import { tokens } from "../../theme";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import { API_URL } from "../../data/AppConstants";
import Checkbox from "@mui/material/Checkbox";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Container from "@mui/material/Container";
import { ThemeProvider } from "@mui/material/styles";
import { Box, Typography, useTheme } from "@mui/material";
import Cookies from "js-cookie";
import { useAuth } from "../../context/AuthContext";
import { useNavigate } from "react-router-dom";

export default function Login() {
  const navigate = useNavigate();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [loginError, setLoginError] = useState(null);
  const { login } = useAuth();

  useEffect(() => {
    // Verificar si la cookie de autenticación está presente al cargar la aplicación
    const isLoggedIn = Cookies.get("isLoggedIn");

    if (isLoggedIn === "true") {
      const isAdmin = JSON.parse(Cookies.get("isAdmin"));
      const userId = JSON.parse(Cookies.get("userId"));
      login(isAdmin, userId);
      isAdmin ? navigate("/dashboard") : navigate("/files");
    }
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    try {
      const response = await fetch(`${API_URL}user/login`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          UserName: data.get("email"),
          Password: data.get("password"),
        }),
      });

      // Si la respuesta es exitosa, puedes usar json() para obtener los datos en formato JSON
      const responseData = await response.json();
      console.log(responseData);
      if (responseData.isSuccess) {
        const typeUser = responseData.type === "admin" ? true : false;
        const loggedUser = responseData.result;

        login(typeUser, loggedUser.id);
        if (data.get("remember") != null) {
          Cookies.set("isLoggedIn", "true", { expires: 1000 });
          Cookies.set("isAdmin", typeUser, { expires: 1000 });
          Cookies.set("userId", loggedUser.id, { expires: 1000 });
        }
        typeUser ? navigate("/dashboard") : navigate("/files");
      } else {
        setLoginError(responseData.message);
      }
      // Ahora puedes acceder a los datos en responseData
    } catch (error) {
      console.error("Error al iniciar sesión:", error);
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Container
        component="main"
        maxWidth="xs"
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          height: "100vh",
        }}
      >
        <CssBaseline />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: colors.primary[800],
            padding: "30px",
            borderRadius: "20px",
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Inicia sesión
          </Typography>
          <Box
            component="form"
            onSubmit={handleSubmit}
            noValidate
            sx={{ mt: 1 }}
          >
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Usuario"
              name="email"
              autoComplete="email"
              color="secondary"
              autoFocus
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Contraseña"
              type="password"
              id="password"
              color="secondary"
              autoComplete="current-password"
            />
            <FormControlLabel
              control={
                <Checkbox
                  value={true}
                  name="remember"
                  color="primary"
                  sx={{
                    "&.Mui-checked": {
                      color: "green",
                    },
                  }}
                />
              }
              label="Recordarme"
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{
                mt: 3,
                mb: 2,
                backgroundColor: colors.greenAccent[600],
                "&:hover": {
                  backgroundColor: colors.greenAccent[300], // Color de fondo al hacer hover
                },
              }}
            >
              Iniciar sesión
            </Button>
            {loginError && (
              <Typography variant="body2" color="error" align="center">
                {loginError}
              </Typography>
            )}
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  );
}
