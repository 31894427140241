import { useState, useEffect } from "react";
import {
  Typography,
  Box,
  useTheme,
  FormControl,
  InputLabel,
  Input,
  Checkbox,
  FormGroup,
  FormControlLabel,
  Button,
  Select,
  MenuItem,
  Grid,
  Chip,
} from "@mui/material";
import { API_URL } from "../data/AppConstants";
import { tokens } from "../theme";
import { useAuth } from "../context/AuthContext";

const ModalCampaing = ({ handleCloseModal, campaing = {} }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const editMode = Object.keys(campaing).length !== 0;
  const [textType, setTextType] = useState(
    editMode && campaing.Type === 1 ? false : true
  );
  const [showingLogo, setShowingLogo] = useState(true);
  const [usuarios, setUsuarios] = useState([]);
  const [files, setFiles] = useState([]);
  const [logos, setLogos] = useState([]);
  const { isMobile } = useAuth();

  const handleChange = (event) => {
    const { name, value, type, checked } = event.target;
    if (name === "Type" && value === 0) {
      setFormValues((prevFormValues) => ({
        ...prevFormValues,
        Files: [],
      }));
      setTextType(true);
    } else if (name === "Type" && value === 1) {
      setFormValues((prevFormValues) => ({
        ...prevFormValues,
        texto_anuncio: "",
      }));
      setTextType(false);
    }

    if (name === "showLogo" && checked) {
      setShowingLogo(true);
    } else if (name === "showLogo" && !checked) {
      setShowingLogo(false);
    }

    setFormValues((prevFormValues) => ({
      ...prevFormValues,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handleCreateCampaing = () => {
    if (formValues.Name === "") {
      alert("Por favor, complete todos los campos.");
    } else {
      console.log(formValues);
      if (editMode) {
        fetch(`${API_URL}campaing/editCampaing/${campaing.id}`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json", // Especificamos que el cuerpo es JSON
          },
          body: JSON.stringify(formValues),
        })
          .then((response) => response.json())
          .then((data) => {
            if (data.ok) {
              alert(data.message);
              handleCloseModal();
            } else {
              alert(data.error);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        fetch(`${API_URL}campaing/create-campaing`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json", // Especificamos que el cuerpo es JSON
          },
          body: JSON.stringify(formValues),
        })
          .then((response) => response.json())
          .then((data) => {
            if (data.ok) {
              alert(data.message);
              handleCloseModal();
            } else {
              alert(data.error);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    }
  };

  const [formValues, setFormValues] = useState({
    Name: editMode ? campaing.Name : "",
    texto_anuncio: editMode ? campaing.texto_anuncio : "",
    showLogo: editMode ? (campaing.showLogo === 1 ? true : false) : true,
    IsActive: editMode ? (campaing.IsActive === 1 ? true : false) : true,
    Type: editMode ? campaing.Type : 0,
    Logo: "",
    LogoPosition: editMode ? campaing.LogoPosition : 0,
    Files: [],
    Users: [],
  });

  // const handleDeleteUser = (value) => {
  //   setFormValues((prevFormValues) => {
  //     const updatedUsers = new Set(prevFormValues.Users);
  //     updatedUsers.delete(value);

  //     return {
  //       ...prevFormValues,
  //       Users: updatedUsers,
  //     };
  //   });
  // };

  const fetchInitialData = async () => {
    try {
      const usersResponse = await fetch(`${API_URL}user/getUsers`, {
        method: "POST",
      });
      const usersData = await usersResponse.json();
      if (usersData.ok) {
        setUsuarios(usersData.users);
      }

      const filesResponse = await fetch(`${API_URL}campaing/get-logos`, {
        method: "POST",
      });
      const filesData = await filesResponse.json();
      if (filesData.ok) {
        const archivos = filesData.files.filter(
          (archivo) => archivo.isLogo === 0
        );
        const logos = filesData.files.filter((logo) => logo.isLogo === 1);
        setFiles(archivos);
        setLogos(logos);

        setFormValues((prevFormValues) => ({
          ...prevFormValues,
          Logo: editMode ? campaing.Logo : logos[0]?.id,
          Files: editMode ? campaing.id_archivos?.split(",") : [],
          Users: editMode ? campaing.UserIds?.split(",").map(Number) : [],
        }));
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchInitialData();
  }, []);

  return (
    <Box
      sx={{
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        backgroundColor: colors.primary[800],
        boxShadow: 24,
        p: 4,
        borderRadius: "8px",
        minWidth: "400px",
        overflowY: isMobile ? "scroll" : "none",
        height: isMobile ? "80%" : "auto",
      }}
    >
      <Typography variant="h4" mb={2}>
        {editMode ? "Editar Campaña" : "Nueva Campaña"}
      </Typography>
      <Grid container spacing={6} alignItems={"flex-start"}>
        <Grid item xs={12} sm={6} md={4}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "15px",
            }}
          >
            <FormControl fullWidth>
              <InputLabel htmlFor="Name">Título de la campaña</InputLabel>
              <Input
                id="Name"
                name="Name"
                value={formValues.Name}
                onChange={handleChange}
              />
            </FormControl>
            <Box>
              <InputLabel
                sx={{
                  fontSize: "15px",
                }}
              >
                Selecciona los usuarios
              </InputLabel>
              <FormControl fullWidth>
                <Select
                  id="Users"
                  name="Users"
                  multiple
                  value={formValues.Users}
                  onChange={handleChange}
                  variant="outlined"
                  renderValue={(selected) => (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        flexWrap: "wrap",
                        gap: "5px",
                        height: "120px",
                        overflowY: "scroll",
                      }}
                    >
                      {selected.map((value) => {
                        const user = usuarios.find((user) => user.id === value);
                        if (user) {
                          return <Chip key={value} label={user.UserName} />;
                        }
                        return null;
                      })}
                    </div>
                  )}
                >
                  {usuarios.map((user) => {
                    return (
                      <MenuItem
                        key={`usuario-${user.id}`}
                        value={user.id}
                        style={{
                          backgroundColor: formValues.Users?.includes(user.id)
                            ? colors.greenAccent[500]
                            : "transparent",
                        }}
                      >
                        {user.UserName}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Box>

            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={formValues.IsActive}
                    onChange={handleChange}
                    name="IsActive"
                    sx={{
                      color: formValues.IsActive ? "green" : "", // Cambia el color según el estado
                      "&.Mui-checked": {
                        color: formValues.IsActive ? "green" : "", // Cambia el color cuando está seleccionado
                      },
                    }}
                  />
                }
                label="Activar campaña"
              />
            </FormGroup>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Box sx={{ display: "flex", flexDirection: "column", gap: "15px" }}>
            <Box>
              <InputLabel
                // id="tipo-label"
                sx={{
                  fontSize: "15px", // Ajusta el tamaño de fuente según tus preferencias
                }}
              >
                Selecciona el tipo de la campaña
              </InputLabel>
              <FormControl fullWidth>
                <Select
                  id="Type"
                  name="Type"
                  value={formValues.Type}
                  onChange={handleChange}
                  variant="outlined"
                >
                  <MenuItem value={0}>Barra de texto abajo</MenuItem>
                  <MenuItem value={1}>Imagen/video completa</MenuItem>
                </Select>
              </FormControl>
            </Box>
            {!textType && (
              <Box>
                <InputLabel
                  sx={{
                    fontSize: "15px",
                  }}
                >
                  Selecciona los archivos
                </InputLabel>
                <FormControl fullWidth>
                  <Select
                    id="Files"
                    name="Files"
                    multiple
                    value={formValues.Files}
                    onChange={handleChange}
                    variant="outlined"
                    renderValue={(selected) => (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          flexWrap: "wrap",
                          gap: "5px",
                          maxHeight: "120px",
                          overflowY: "scroll",
                        }}
                      >
                        {selected.map((value) => {
                          const file = files.find((file) => file.id === value);
                          if (file) {
                            return <Chip key={value} label={file.Name} />;
                          }
                          return null;
                        })}
                      </div>
                    )}
                  >
                    {files.map((file) => {
                      return (
                        <MenuItem
                          key={`file-${file.id}`}
                          value={file.id}
                          style={{
                            backgroundColor: formValues.Files?.includes(file.id)
                              ? colors.greenAccent[500]
                              : "transparent",
                          }}
                        >
                          {file.Name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Box>
            )}

            {textType && (
              <FormControl fullWidth>
                <InputLabel htmlFor="texto_anuncio">
                  Texto del anuncio
                </InputLabel>
                <Input
                  id="texto_anuncio"
                  name="texto_anuncio"
                  value={formValues.texto_anuncio}
                  onChange={handleChange}
                />
              </FormControl>
            )}
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Box sx={{ display: "flex", flexDirection: "column", gap: "15px" }}>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={formValues.showLogo}
                    onChange={handleChange}
                    name="showLogo"
                    sx={{
                      color: formValues.showLogo ? "green" : "", // Cambia el color según el estado
                      "&.Mui-checked": {
                        color: formValues.showLogo ? "green" : "", // Cambia el color cuando está seleccionado
                      },
                    }}
                  />
                }
                label="Activar logo en esquinas (siempre activo)"
              />
            </FormGroup>
            {showingLogo && (
              <>
                <Box>
                  <InputLabel
                    sx={{
                      fontSize: "15px",
                    }}
                  >
                    Selecciona el logo
                  </InputLabel>
                  <FormControl fullWidth>
                    <Select
                      id="Logo"
                      name="Logo"
                      value={formValues.Logo}
                      onChange={handleChange}
                      variant="outlined"
                    >
                      {logos.map((logo) => {
                        return (
                          <MenuItem
                            key={`logo-${logo.id}`}
                            value={logo.id}
                            style={{
                              backgroundColor:
                                formValues.Logo === logo.id
                                  ? colors.greenAccent[500]
                                  : "transparent",
                            }}
                          >
                            {logo.Name}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </Box>
                <Box>
                  <InputLabel
                    sx={{
                      fontSize: "15px",
                    }}
                  >
                    Esquina donde colocar el logo
                  </InputLabel>
                  <FormControl fullWidth>
                    <Select
                      id="LogoPosition"
                      name="LogoPosition"
                      value={formValues.LogoPosition}
                      onChange={handleChange}
                      variant="outlined"
                    >
                      <MenuItem
                        value={0}
                        style={{
                          backgroundColor:
                            formValues.LogoPosition === 0
                              ? colors.greenAccent[500]
                              : "transparent",
                        }}
                      >
                        Arriba derecha
                      </MenuItem>
                      <MenuItem
                        value={1}
                        style={{
                          backgroundColor:
                            formValues.LogoPosition === 1
                              ? colors.greenAccent[500]
                              : "transparent",
                        }}
                      >
                        Arriba Izquierda
                      </MenuItem>
                      <MenuItem
                        value={2}
                        style={{
                          backgroundColor:
                            formValues.LogoPosition === 2
                              ? colors.greenAccent[500]
                              : "transparent",
                        }}
                      >
                        Abajo Derecha
                      </MenuItem>
                      <MenuItem
                        value={3}
                        style={{
                          backgroundColor:
                            formValues.LogoPosition === 3
                              ? colors.greenAccent[500]
                              : "transparent",
                        }}
                      >
                        Abajo Izquierda
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Box>
              </>
            )}
          </Box>
        </Grid>

        <Grid item xs={12}>
          <Box display="flex" justifyContent="space-around" mt={3}>
            <Button
              variant="contained"
              color="secondary"
              onClick={handleCreateCampaing}
            >
              Guardar
            </Button>
            <Button variant="contained" onClick={handleCloseModal}>
              Cerrar
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ModalCampaing;
