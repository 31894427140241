import { useState, useEffect } from "react";
import { ProSidebar, Menu, MenuItem } from "react-pro-sidebar";
import { Box, IconButton, Typography, useTheme } from "@mui/material";
import { Link } from "react-router-dom";
import "react-pro-sidebar/dist/css/styles.css";
import { tokens } from "../../theme";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import PeopleOutlinedIcon from "@mui/icons-material/PeopleOutlined";
import ContactsOutlinedIcon from "@mui/icons-material/ContactsOutlined";

import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import FolderIcon from "@mui/icons-material/Folder";
import { useAuth } from "../../context/AuthContext";
const Item = ({ title, to, icon, selected, setSelected }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <MenuItem
      active={selected === title}
      style={{
        color: colors.grey[100],
      }}
      onClick={() => setSelected(title)}
      icon={icon}
    >
      <Typography>{title}</Typography>
      <Link to={to} />
    </MenuItem>
  );
};

const Sidebar = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [isCollapsed, setIsCollapsed] = useState(true);
  const [selected, setSelected] = useState("Dashboard");
  const [currentYear, setCurrentYear] = useState("2023");
  const { isAdmin, mobileMenu, toogleMobileMenu, isMobile } = useAuth();
  useEffect(() => {
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    setCurrentYear(year);
  }, []);

  return (
    <Box
      sx={{
        "& .pro-sidebar-inner": {
          background: `${colors.primary[400]} !important`,
        },
        "& .pro-icon-wrapper": {
          backgroundColor: "transparent !important",
        },
        "& .pro-inner-item": {
          padding: "5px 35px 5px 20px !important",
        },
        "& .pro-inner-item:hover": {
          color: "#868dfb !important",
        },
        "& .pro-menu-item.active": {
          color: "#6870fa !important",
        },
      }}
      style={{
        position: "relative",
        height: "100%",
        display: isMobile && mobileMenu ? "none" : "block",
      }}
    >
      <ProSidebar collapsed={isCollapsed}>
        <Menu iconShape="square">
          {/* LOGO AND MENU ICON */}
          <MenuItem
            onClick={() =>
              isMobile ? toogleMobileMenu(true) : setIsCollapsed(!isCollapsed)
            }
            icon={isCollapsed ? <MenuOutlinedIcon /> : undefined}
            style={{
              margin: "10px 0 20px 0",
              color: colors.grey[100],
            }}
          >
            {!isCollapsed && (
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                ml="15px"
              >
                {/* <Typography variant="h3" color={colors.grey[100]}>
                  CUVO
                </Typography> */}
                <Box mb="25px">
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <img
                      alt="profile-user"
                      width="140px"
                      height="auto"
                      src={`../../assets/logo.png`}
                      style={{ cursor: "pointer" }}
                    />
                  </Box>
                </Box>
                <IconButton onClick={() => setIsCollapsed(!isCollapsed)}>
                  <MenuOutlinedIcon />
                </IconButton>
              </Box>
            )}
          </MenuItem>

          <Box paddingLeft={isCollapsed ? undefined : "10%"}>
            {isAdmin && (
              <>
                <Item
                  title="Panel de control"
                  to="/dashboard"
                  icon={<HomeOutlinedIcon />}
                  selected={selected}
                  setSelected={setSelected}
                />
                <Item
                  title="Usuarios"
                  to="/users"
                  icon={<PeopleOutlinedIcon />}
                  selected={selected}
                  setSelected={setSelected}
                />
                <Item
                  title="Campañas"
                  to="/contacts"
                  icon={<ContactsOutlinedIcon />}
                  selected={selected}
                  setSelected={setSelected}
                />
              </>
            )}
            {!isAdmin && (
              <Item
                title="Archivos"
                to="/files"
                icon={<FolderIcon />}
                selected={selected}
                setSelected={setSelected}
              />
            )}
          </Box>
          {!isCollapsed && (
            <Box
              style={{
                position: "absolute",
                bottom: 0,
                paddingLeft: 0,
                margin: "0px auto",
              }}
            >
              <Typography
                variant="h5"
                color={colors.grey[200]}
                sx={{ m: "0px auto" }}
                textAlign="center"
                paddingBottom="20px"
              >
                © {currentYear} Cuvo Streaming. Todos los derechos reservados.
                App desarrollada por{" "}
                <strong>
                  <span>
                    <a
                      href="https://esloogan.com"
                      target="_blank"
                      rel="noreferrer noopener"
                    >
                      Esloogan
                    </a>
                  </span>
                </strong>
              </Typography>
            </Box>
          )}
        </Menu>
      </ProSidebar>
    </Box>
  );
};

export default Sidebar;
