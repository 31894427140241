import { Box, Typography, useTheme, IconButton } from "@mui/material";
import { tokens } from "../../theme";
import { useState } from "react";
import Collapse from "@mui/material/Collapse";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import SettingsIcon from "@mui/icons-material/Settings";
import SyncIcon from "@mui/icons-material/Sync";
import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";
import ModalConfig from "../../components/ModalConfig";
import Modal from "@mui/material/Modal";
import { useAuth } from "../../context/AuthContext";

const UserCard = ({ user, handleSync, handle }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [expanded, setExpanded] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { isMobile } = useAuth();

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };
  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    handle();
    setIsModalOpen(false);
  };

  return (
    <Box p="25px" bgcolor={colors.primary[700]} borderRadius="15px">
      {/* Barra de información del usuario */}
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        bgcolor={colors.primary[400]}
        p="10px"
        borderRadius="4px"
        color="primary.contrastText"
      >
        <Box display="flex" flexDirection="row" gap="20px">
          <Typography
            variant="h2"
            color={colors.grey[100]}
            sx={{ m: "0 0 5px 0" }}
          >
            <b>Usuario:</b> {user?.UserName}
          </Typography>
          <IconButton
            onClick={handleExpandClick}
            style={{
              backgroundColor: colors.blueAccent[500],
              width: "40px",
              height: "40px",
              borderRadius: "15px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              color: "white", // Color del icono
            }}
          >
            <ExpandMoreIcon
              style={{
                width: "30px",
                height: "30px",
              }}
            />
          </IconButton>
        </Box>
        <IconButton
          style={{
            backgroundColor: colors.blueAccent[800],
            width: "40px",
            height: "40px",
            borderRadius: "15px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            color: "white",
          }}
          onClick={() => handleOpenModal()}
        >
          <SettingsIcon />
        </IconButton>
      </Box>

      {/* Contenedor desplegable con información adicional */}
      <Collapse in={expanded}>
        <Box
          p="10px"
          bgcolor="background.default"
          borderRadius="0 0  14px 14px"
          fontSize={isMobile ? "16px" : "20px"}
        >
          <b>Carpeta:</b> {user?.Folder}
          <br />
          <b>Ubicación:</b> {user?.Location}
          <br />
          <b>Creado:</b> {user?.Created?.replace("T", " ").replace(".000Z", "")}
          <br />
          <b>Ultima sesión:</b>{" "}
          {user?.Created?.replace("T", " ").replace(".000Z", "")}
          <br />
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <div style={{ display: "flex", flexDirection: "row" }}>
              {user?.IsActive ? (
                <CheckIcon style={{ color: "green" }} />
              ) : (
                <ClearIcon style={{ color: "red" }} />
              )}
              <Typography
                color={colors.grey[100]}
                sx={{ ml: "5px" }}
                variant="h4"
              >
                {user?.IsActive ? "Activo" : "Inactivo"}
              </Typography>
            </div>
            <div style={{ display: "flex", flexDirection: "row" }}>
              {user?.IsActive ? (
                <CheckIcon style={{ color: "green" }} />
              ) : (
                <ClearIcon style={{ color: "red" }} />
              )}
              <Typography
                color={colors.grey[100]}
                sx={{ ml: "5px" }}
                variant="h4"
              >
                Es Pro
              </Typography>
            </div>
            <div></div>
          </div>
        </Box>
      </Collapse>
      <IconButton
        style={{
          backgroundColor: "#218838",
          height: "40px",
          borderRadius: "10px",
          margin: "20px auto",
          marginBottom: "0",
          display: "flex",
          fontWeight: "semibold",
          justifyContent: "center",
          alignItems: "center",
          color: "white", // Color del icono
        }}
        onClick={() => handleSync()}
      >
        <SyncIcon /> Sincronizar con drive
      </IconButton>
      <Box textAlign="center" fontSize="20px">
        <b>Ultima actualización:</b>{" "}
        {user?.LastUpdated?.replace("T", " ").replace(".000Z", "")}
      </Box>
      <Modal open={isModalOpen} onClose={handleCloseModal}>
        <div>
          <ModalConfig handleCloseModal={handleCloseModal} usuario={user} />
        </div>
      </Modal>
    </Box>
  );
};

export default UserCard;
