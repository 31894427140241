import { useState } from "react";
import {
  Typography,
  Box,
  useTheme,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
} from "@mui/material";
import { API_URL } from "../data/AppConstants";
import { tokens } from "../theme";

const ModalConfig = ({ handleCloseModal, usuario = {} }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const [formValues, setFormValues] = useState({
    IsBoot: usuario.IsBoot,
    Orientation: usuario.Orientation,
    LapseImage: usuario.LapseImage,
    TimeUpdate: usuario.TimeUpdate,
    ShowWeather: usuario.ShowWeather,
    FileAnimation: usuario.FileAnimation,
  });

  const handleChange = (event) => {
    const { name, value, type, checked } = event.target;
    setFormValues((prevFormValues) => ({
      ...prevFormValues,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handleEditUserConfig = () => {
    fetch(`${API_URL}user/editUserConfig/${usuario.id}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json", // Especificamos que el cuerpo es JSON
      },
      body: JSON.stringify(formValues),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.ok) {
          alert(data.message);
          handleCloseModal();
        } else {
          alert(data.error);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <Box
      sx={{
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        backgroundColor: colors.primary[800],
        boxShadow: 24,
        p: 4,
        borderRadius: "8px",
        minWidth: "400px",
      }}
    >
      <Typography variant="h4" mb={2}>
        Configuración
      </Typography>
      <Box style={{ marginBottom: "20px" }}>
        <InputLabel
          sx={{
            fontSize: "15px",
          }}
        >
          Iniciar app cuando Android
        </InputLabel>
        <FormControl fullWidth>
          <Select
            id="IsBoot"
            name="IsBoot"
            value={formValues.IsBoot}
            onChange={handleChange}
            variant="outlined"
          >
            <MenuItem value={0}>Desactivado</MenuItem>
            <MenuItem value={1}>Activado</MenuItem>
          </Select>
        </FormControl>
      </Box>
      <Box sx={{ marginBottom: "20px" }}>
        <InputLabel
          sx={{
            fontSize: "15px",
          }}
        >
          Orientación de pantalla
        </InputLabel>
        <FormControl fullWidth>
          <Select
            id="Orientation"
            name="Orientation"
            value={formValues.Orientation}
            onChange={handleChange}
            variant="outlined"
          >
            <MenuItem value={0}>Automático</MenuItem>
            <MenuItem value={1}>Horizontal</MenuItem>
            <MenuItem value={2}>Vertical Izquierdo</MenuItem>
            <MenuItem value={3}>Vertical Derecha</MenuItem>
          </Select>
        </FormControl>
      </Box>
      <Box style={{ marginBottom: "20px" }}>
        <InputLabel
          sx={{
            fontSize: "15px",
          }}
        >
          Duración de anuncios de imagenes
        </InputLabel>
        <FormControl fullWidth>
          <Select
            id="LapseImage"
            name="LapseImage"
            value={formValues.LapseImage}
            onChange={handleChange}
            variant="outlined"
          >
            <MenuItem value={1}>1 sg</MenuItem>
            <MenuItem value={2}>2 sg</MenuItem>
            <MenuItem value={3}>3 sg</MenuItem>
            <MenuItem value={4}>5 sg</MenuItem>
            <MenuItem value={5}>10 sg</MenuItem>
            <MenuItem value={6}>15 sg</MenuItem>
            <MenuItem value={7}>20 sg</MenuItem>
            <MenuItem value={8}>25 sg</MenuItem>
            <MenuItem value={9}>30 sg</MenuItem>
          </Select>
        </FormControl>
      </Box>
      <Box style={{ marginBottom: "20px" }}>
        <InputLabel
          sx={{
            fontSize: "15px",
          }}
        >
          Sincronizar archivos cada
        </InputLabel>
        <FormControl fullWidth>
          <Select
            id="TimeUpdate"
            name="TimeUpdate"
            value={formValues.TimeUpdate}
            onChange={handleChange}
            variant="outlined"
          >
            <MenuItem value={1}>30sg</MenuItem>
            <MenuItem value={2}>5 min</MenuItem>
            <MenuItem value={3}>30 min</MenuItem>
            <MenuItem value={4}>1h</MenuItem>
            <MenuItem value={5}>8h</MenuItem>
            <MenuItem value={6}>12h</MenuItem>
            <MenuItem value={7}>1 día</MenuItem>
            <MenuItem value={8}>1 semana</MenuItem>
          </Select>
        </FormControl>
      </Box>
      <Box style={{ marginBottom: "20px" }}>
        <InputLabel
          sx={{
            fontSize: "15px",
          }}
        >
          Mostrar clima y hora
        </InputLabel>
        <FormControl fullWidth>
          <Select
            id="ShowWeather"
            name="ShowWeather"
            value={formValues.ShowWeather}
            onChange={handleChange}
            variant="outlined"
          >
            <MenuItem value={0}>Desactivado</MenuItem>
            <MenuItem value={1}>Discreto</MenuItem>
            <MenuItem value={2}>Completa</MenuItem>
          </Select>
        </FormControl>
      </Box>
      <Box style={{ marginBottom: "20px" }}>
        <InputLabel
          sx={{
            fontSize: "15px",
          }}
        >
          Mostrar animación entre anuncios
        </InputLabel>
        <FormControl fullWidth>
          <Select
            id="TimeUpdate"
            name="TimeUpdate"
            value={formValues.TimeUpdate}
            onChange={handleChange}
            variant="outlined"
          >
            <MenuItem value={0}>Desactivado</MenuItem>
            <MenuItem value={1}>Fade</MenuItem>
            <MenuItem value={2}>Scale</MenuItem>
          </Select>
        </FormControl>
      </Box>
      <Box display="flex" justifyContent="space-around" mt={3}>
        <Button
          variant="contained"
          color="secondary"
          onClick={handleEditUserConfig}
        >
          Guardar
        </Button>
        {/* Botón para cerrar el modal */}
        <Button variant="contained" onClick={handleCloseModal}>
          Cerrar
        </Button>
      </Box>
    </Box>
  );
};

export default ModalConfig;
