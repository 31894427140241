import { Box, Typography, useTheme, IconButton } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";
import { DataGrid, esES } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import { useState, useEffect } from "react";
import { API_URL } from "../../data/AppConstants";
import Modal from "@mui/material/Modal";
import ModalCampaing from "../../components/ModalCampaing";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { Toaster, toast } from "sonner";
import { useAuth } from "../../context/AuthContext";

const Contacts = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [campaings, setCampaings] = useState([]);
  const [campaingToEdit, setCampaingToEdit] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { isMobile } = useAuth();

  const fetchCampaings = () => {
    fetch(`${API_URL}campaing/get-campaings`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        if (data.ok) {
          setCampaings(data.campaings);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };
  const handleCloseModal = () => {
    setIsModalOpen(false);
    fetchCampaings();
  };
  console.log("mobile", isMobile);
  const columns = [
    { field: "id", headerName: "id", flex: 0.5 },
    {
      field: "Type",
      headerName: "Tipo",
      flex: isMobile ? "none" : 1,
      renderCell: ({ row: { Type } }) => {
        return (
          <Typography color={colors.grey[100]} sx={{ ml: "5px" }}>
            {Type === 0 ? "Texto" : "Video/imagen"}
          </Typography>
        );
      },
    },
    {
      field: "Name",
      headerName: "Nombre de la campaña",
      flex: isMobile ? "none" : 1,
      cellClassName: "name-column--cell",
    },
    {
      field: "IsActive",
      headerName: "Activo",
      headerAlign: "center",
      align: "center",
      flex: isMobile ? "none" : 1,
      renderCell: ({ row: { IsActive } }) => {
        return (
          <Box
            width="60%"
            m="0 auto"
            p="5px"
            display="flex"
            justifyContent="center"
            backgroundColor="transparent"
            borderRadius="4px"
          >
            {IsActive ? (
              <CheckIcon style={{ color: "green" }} />
            ) : (
              <ClearIcon style={{ color: "red" }} />
            )}
            <Typography color={colors.grey[100]} sx={{ ml: "5px" }}>
              {IsActive ? "Activa" : "Inactiva"}
            </Typography>
          </Box>
        );
      },
    },
    {
      field: "actions",
      headerName: "Acciones",
      width: 160,
      renderCell: ({ row }) => {
        return (
          <Box display="flex" justifyContent="center" alignItems="center">
            <IconButton
              onClick={() => handleEditCampaing(row.id)}
              color="secondary"
              aria-label="Editar"
            >
              <EditIcon />
            </IconButton>
            <IconButton
              onClick={() => handleDeleteCampaing(row.id)}
              color="secondary"
              aria-label="Eliminar"
            >
              <DeleteIcon />
            </IconButton>
          </Box>
        );
      },
    },
  ];

  const handleDeleteCampaing = (id) => {
    const campaing = campaings.filter((file) => file.id === id)[0];
    // eslint-disable-next-line no-restricted-globals
    const decision = confirm(
      `Esta seguro que desea eliminar la campaña ${campaing.Name}`
    );
    if (decision) {
      fetch(`${API_URL}campaing/deleteCampaing/${id}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.ok) {
            toast.success(data.message);
            fetchCampaings();
          } else {
            toast.error(data.message);
          }
        })
        .catch((err) => {
          toast.error(err.error);
          console.log(err);
        });
    }
  };

  const handleEditCampaing = (id) => {
    const userFilter = campaings.filter((user) => user.id === id);
    setCampaingToEdit(userFilter[0]);
    handleOpenModal();
  };
  useEffect(() => {
    fetchCampaings();
  }, []);
  return (
    <Box m="20px">
      <Toaster richColors />
      <Header
        title="CAMPAÑAS"
        subtitle="Lista de las campañas publicitarias creadas"
        showButton2={true}
        handleOpenModal2={handleOpenModal}
      />
      <Box
        m="40px 0 0 0"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${colors.grey[100]} !important`,
          },
        }}
      >
        <DataGrid
          rows={campaings}
          columns={columns}
          // components={{ Toolbar: GridToolbar }}
          localeText={esES.components.MuiDataGrid.defaultProps.localeText}
        />
      </Box>
      {/* <button onClick={() => handleOpenModal()}>Abrir</button> */}
      <Modal open={isModalOpen} onClose={handleCloseModal}>
        <div>
          <ModalCampaing
            handleCloseModal={handleCloseModal}
            campaing={campaingToEdit}
          />
        </div>
      </Modal>
    </Box>
  );
};

export default Contacts;
