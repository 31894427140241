import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useAuth } from "../context/AuthContext";
import Cookies from "js-cookie";

const PrivateRoute = ({ element, ...rest }) => {
  const { isAuthenticated } = useAuth();
  const isLoggedIn = Cookies.get("isLoggedIn");

  return isAuthenticated || isLoggedIn ? (
    <Outlet />
  ) : (
    <Navigate to="/" replace />
  );
};

export default PrivateRoute;
