import { useState, useEffect } from "react";
import { Box, Typography, useTheme } from "@mui/material";
import { tokens } from "../../theme";

import { API_URL } from "../../data/AppConstants";

const Dashboard = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [usuarios, setUsuarios] = useState([]);
  const [campaings, setCampaings] = useState([]);
  const isMobile =
    window.innerWidth <= 600 || /Mobi|Android/i.test(navigator.userAgent);
  console.log(isMobile);
  const fetchUsersData = () => {
    fetch(`${API_URL}user/getUsers`, {
      method: "POST",
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.ok) {
          const reversedUsers = data.users.reverse();

          // Obtener los primeros 13 elementos
          const primeros13Usuarios = reversedUsers.slice(0, 10);
          console.log(primeros13Usuarios);
          setUsuarios(primeros13Usuarios);
          // setUsuarios(data.users);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const fetchCampaing = () => {
    fetch(`${API_URL}campaing/get-campaings`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        if (data.ok) {
          const reversedUsers = data.campaings.reverse();

          // Obtener los primeros 13 elementos
          const primeros13Usuarios = reversedUsers.slice(0, 10);
          setCampaings(primeros13Usuarios);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    fetchUsersData();
    fetchCampaing();
  }, []);
  const cases = {
    1: "1",
    2: "2",
    3: "2",
    4: "3",
    5: "3",
    6: "4",
    7: "4",
    8: "5",
    9: "5",
    10: "5",
  };
  return (
    <Box m="20px">
      {/* HEADER */}
      {/*<Box display="flex" justifyContent="space-between" alignItems="center">
         <Box>
          <Button
            sx={{
              backgroundColor: colors.blueAccent[700],
              color: colors.grey[100],
              fontSize: "14px",
              fontWeight: "bold",
              padding: "10px 20px",
            }}
          >
            <DownloadOutlinedIcon sx={{ mr: "10px" }} />
            Download Reports
          </Button>
        </Box> 
      </Box>*/}

      {/* GRID & CHARTS */}
      <Box
        display="grid"
        gridTemplateColumns="repeat(12, 1fr)"
        gridAutoRows="140px"
        gap="20px"
      >
        {/* ROW 1 */}
        {/* <Box
          gridColumn="span 3"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <StatBox
            title="12,361"
            subtitle="Emails Sent"
            progress="0.75"
            increase="+14%"
            icon={
              <EmailIcon
                sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
              />
            }
          />
        </Box>
        <Box
          gridColumn="span 3"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <StatBox
            title="431,225"
            subtitle="Sales Obtained"
            progress="0.50"
            increase="+21%"
            icon={
              <PointOfSaleIcon
                sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
              />
            }
          />
        </Box>
        <Box
          gridColumn="span 3"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <StatBox
            title="32,441"
            subtitle="New Clients"
            progress="0.30"
            increase="+5%"
            icon={
              <PersonAddIcon
                sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
              />
            }
          />
        </Box>
        <Box
          gridColumn="span 3"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <StatBox
            title="1,325,134"
            subtitle="Traffic Received"
            progress="0.80"
            increase="+43%"
            icon={
              <TrafficIcon
                sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
              />
            }
          />
        </Box> */}

        {/* ROW 2 */}
        <Box
          gridColumn={`span ${isMobile ? "12" : "6"}`}
          gridRow={`span ${isMobile ? cases[campaings.length] : "4"}`}
          backgroundColor={colors.primary[400]}
          overflow="auto"
        >
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            borderBottom={`4px solid ${colors.primary[500]}`}
            colors={colors.grey[100]}
            p="15px"
          >
            <Typography color={colors.grey[100]} variant="h5" fontWeight="600">
              Ultimas campañas
            </Typography>
          </Box>
          {campaings.map((campaing, i) => (
            <Box
              key={`${campaing.id}-${i}`}
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              borderBottom={`4px solid ${colors.primary[500]}`}
              p="15px"
            >
              <Box flex="1">
                <Typography
                  color={colors.greenAccent[500]}
                  variant="h5"
                  fontWeight="600"
                >
                  {campaing.id}
                </Typography>
                <Typography color={colors.grey[100]}>
                  {campaing.Name}
                </Typography>
              </Box>
              <Box flex="1" color={colors.grey[100]}>
                {campaing.Created.substring(0, 10)}
              </Box>
              <Box
                flex="1"
                backgroundColor={colors.greenAccent[500]}
                p="5px 10px"
                borderRadius="4px"
                maxWidth="20%"
                textAlign="center"
              >
                {campaing.Type === 0 ? "Texto anuncio" : "Video / imagen"}
              </Box>
            </Box>
          ))}
        </Box>
        <Box
          gridColumn={`span ${isMobile ? "12" : "6"}`}
          gridRow={`span ${isMobile ? cases[usuarios.length] : "5"}`}
          backgroundColor={colors.primary[400]}
          overflow="auto"
        >
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            borderBottom={`4px solid ${colors.primary[500]}`}
            colors={colors.grey[100]}
            p="15px"
          >
            <Typography color={colors.grey[100]} variant="h5" fontWeight="600">
              Ultimos usuarios
            </Typography>
          </Box>
          {usuarios.map((user, i) => (
            <Box
              key={`${user.id}-${i}`}
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              borderBottom={`4px solid ${colors.primary[500]}`}
              p="15px"
            >
              <Box flex="1">
                <Typography
                  color={colors.greenAccent[500]}
                  variant="h5"
                  fontWeight="600"
                >
                  {user.id}
                </Typography>
                <Typography color={colors.grey[100]}>
                  {user.UserName}
                </Typography>
              </Box>
              <Box flex="1" color={colors.grey[100]}>
                {user.Created.substring(0, 10)}
              </Box>
              <Box
                flex="1"
                backgroundColor={colors.greenAccent[500]}
                p="5px 10px"
                borderRadius="4px"
                maxWidth="25%"
                textAlign="center"
              >
                {user.Location}
              </Box>
            </Box>
          ))}
        </Box>

        {/* ROW 3 */}
        {/* <Box
          gridColumn="span 4"
          gridRow="span 2"
          backgroundColor={colors.primary[400]}
          p="30px"
        >
          <Typography variant="h5" fontWeight="600">
            Campaign
          </Typography>
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            mt="25px"
          >
            <ProgressCircle size="125" />
            <Typography
              variant="h5"
              color={colors.greenAccent[500]}
              sx={{ mt: "15px" }}
            >
              $48,352 revenue generated
            </Typography>
            <Typography>Includes extra misc expenditures and costs</Typography>
          </Box>
        </Box>
        <Box
          gridColumn="span 4"
          gridRow="span 2"
          backgroundColor={colors.primary[400]}
        >
          <Typography
            variant="h5"
            fontWeight="600"
            sx={{ padding: "30px 30px 0 30px" }}
          >
            Sales Quantity
          </Typography>
          <Box height="250px" mt="-20px">
            <BarChart isDashboard={true} />
          </Box>
        </Box>
        <Box
          gridColumn="span 4"
          gridRow="span 2"
          backgroundColor={colors.primary[400]}
          padding="30px"
        >
          <Typography
            variant="h5"
            fontWeight="600"
            sx={{ marginBottom: "15px" }}
          >
            Geography Based Traffic
          </Typography>
          <Box height="200px">
            <GeographyChart isDashboard={true} />
          </Box>
        </Box> */}
      </Box>
    </Box>
  );
};

export default Dashboard;
