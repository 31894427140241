import { Box, Typography, useTheme, IconButton } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";
import { DataGrid, esES } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import AdminPanelSettingsOutlinedIcon from "@mui/icons-material/AdminPanelSettingsOutlined";
import LockOpenOutlinedIcon from "@mui/icons-material/LockOpenOutlined";
import Header from "../../components/Header";
import { useState, useEffect } from "react";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import Folder from "@mui/icons-material/Folder";
import ModalUser from "../../components/ModalUser";
import Modal from "@mui/material/Modal";
import { API_URL } from "../../data/AppConstants";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../context/AuthContext";

const Team = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [usuarios, setUsuarios] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [userToEdit, setUserToEdit] = useState([]);
  const [shouldOpenModal, setShouldOpenModal] = useState(false);
  const navigate = useNavigate();
  const { setUserId, isMobile } = useAuth();

  useEffect(() => {
    if (shouldOpenModal && userToEdit) {
      handleOpenModal();
      setShouldOpenModal(false); // Establecer de nuevo en false para evitar ejecuciones posteriores
    }
  }, [shouldOpenModal, userToEdit]);

  const handleEdit = (id) => {
    const userFilter = usuarios.filter((user) => user.id === id);
    setUserToEdit(userFilter[0]);
    setShouldOpenModal(true); // Establecer en true cuando deseas abrir el modal
  };

  const fetchUsersData = () => {
    fetch(`${API_URL}user/getUsers`, {
      method: "POST",
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        if (data.ok) {
          setUsuarios(data.users);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleDelete = (id) => {
    const user = usuarios.filter((user) => user.id === id)[0];
    console.log(user);
    // eslint-disable-next-line no-restricted-globals
    const decision = confirm(
      `Esta seguro que desea eliminar al usuario ${user.UserName}`
    );
    if (decision) {
      fetch(`${API_URL}user/deleteUser/${id}`, {
        method: "POST",
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.ok) {
            alert("Usuario eliminado correctamente");
            fetchUsersData();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    fetchUsersData();
  };

  const columns = [
    { field: "id", headerName: "ID", flex: isMobile ? 1 : "none" },
    {
      field: "UserName",
      headerName: "Nombre",
      flex: isMobile ? "none" : 1,
      cellClassName: "name-column--cell",
    },
    {
      field: "Folder",
      headerName: "Carpeta",
      flex: isMobile ? "none" : 1,
      headerAlign: "left",
      align: "left",
    },
    {
      field: "LastSession",
      headerName: "Ultima Sesión",
      flex: isMobile ? "none" : 1,
      renderCell: ({ row: { LastSession } }) => {
        // Aplicar replace al valor de LastSession
        const fechaFormateada = new Date(LastSession)?.toLocaleString("es-ES", {
          day: "numeric",
          month: "numeric",
          year: "numeric",
          hour: "2-digit",
          minute: "2-digit",
        });

        return <div>{fechaFormateada}</div>;
      },
    },
    {
      field: "IsActive",
      headerName: "Activo",
      flex: isMobile ? "none" : 1,
      renderCell: ({ row: { IsActive } }) => {
        return (
          <Box
            width="60%"
            m="0 auto"
            p="5px"
            display="flex"
            justifyContent="center"
            backgroundColor="transparent"
            borderRadius="4px"
          >
            {IsActive ? (
              <CheckIcon style={{ color: "green" }} />
            ) : (
              <ClearIcon style={{ color: "red" }} />
            )}
            <Typography color={colors.grey[100]} sx={{ ml: "5px" }}>
              {IsActive ? "Activo" : "Inactivo"}
            </Typography>
          </Box>
        );
      },
    },
    {
      field: "IsPro",
      headerName: "Rol",
      flex: isMobile ? "none" : 1,
      renderCell: ({ row: { IsPro } }) => {
        return (
          <Box
            width="60%"
            m="0 auto"
            p="5px"
            display="flex"
            justifyContent="center"
            backgroundColor={
              IsPro ? colors.greenAccent[600] : colors.greenAccent[700]
            }
            borderRadius="4px"
          >
            {IsPro === 1 && <AdminPanelSettingsOutlinedIcon />}
            {IsPro === 0 && <LockOpenOutlinedIcon />}
            <Typography color={colors.grey[100]} sx={{ ml: "5px" }}>
              {IsPro === 1 ? "Pro" : "Normal"}
            </Typography>
          </Box>
        );
      },
    },
    {
      field: "actions",
      headerName: "Acciones",
      width: 160,
      renderCell: ({ row }) => {
        return (
          <Box display="flex" justifyContent="center" alignItems="center">
            <IconButton
              onClick={() => handleEdit(row.id)}
              color="secondary"
              aria-label="Editar"
            >
              <EditIcon />
            </IconButton>
            <IconButton
              onClick={() => handleDelete(row.id)}
              color="secondary"
              aria-label="Eliminar"
            >
              <DeleteIcon />
            </IconButton>
            <IconButton
              onClick={() => {
                setUserId(row.id);
                navigate(`/files`);
              }}
              color="secondary"
              aria-label="Archivos"
            >
              <Folder />
            </IconButton>
          </Box>
        );
      },
    },
  ];
  useEffect(() => {
    fetchUsersData();
  }, []);

  return (
    <Box m="20px">
      <Header
        title="Usuarios"
        subtitle="Gestiona los usuarios"
        showButton={true}
      />
      {
        <Modal open={isModalOpen} onClose={handleCloseModal}>
          <div>
            <ModalUser
              handleCloseModal={handleCloseModal}
              usuario={userToEdit}
            />
          </div>
        </Modal>
      }
      <Box
        m="40px 0 0 0"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
        }}
      >
        <DataGrid
          rows={usuarios}
          columns={columns}
          localeText={esES.components.MuiDataGrid.defaultProps.localeText}
        />
      </Box>
    </Box>
  );
};

export default Team;
