import { useEffect, useState } from "react";
import {
  Typography,
  Box,
  useTheme,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
} from "@mui/material";
import { API_URL } from "../data/AppConstants";
import { tokens } from "../theme";
import dayjs from "dayjs";
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { MobileDateTimePicker } from "@mui/x-date-pickers/MobileDateTimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import esLocale from "dayjs/locale/es";
dayjs.locale(esLocale);

const ModalPromotion = ({ handleCloseModal, usuario = {} }) => {
  const theme = useTheme();

  const colors = tokens(theme.palette.mode);
  console.log(usuario);
  let editMode = false;
  // let Start = "2022-04-17T15:30";
  // let End = "2022-04-17T15:30";
  let Start = dayjs(); // Inicializar con la fecha y hora actuales
  let End = dayjs();
  let Action = 0;
  let idPromotion = 0;

  if (usuario.promotions.length > 0) {
    const promotion = usuario.promotions.find(
      (promotion) => promotion.FileId === usuario.FileId
    );
    if (promotion !== undefined) {
      editMode = true;
      Start = promotion?.Start?.slice(0, 16);
      End = promotion?.End?.slice(0, 16);
      Action = promotion.Action;
      idPromotion = promotion.id;
    }
    console.log(promotion);
    console.log(Start);
  }

  // Estados para capturar los valores del formulario
  const [formValues, setFormValues] = useState({
    Start: editMode ? dayjs(Start) : dayjs(),
    End: editMode ? dayjs(End) : dayjs(),
    Action: editMode ? Action : "0",
  });
  useEffect(() => {
    console.log(formValues);
  }, [formValues]);
  // Función para manejar el cambio en los campos del formulario
  const handleChange = (event) => {
    const { name, value, type, checked } = event.target;
    setFormValues((prevFormValues) => ({
      ...prevFormValues,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handleChangeDate = (value, newdate) => {
    setFormValues((prevFormValues) => ({
      ...prevFormValues,
      [value]: newdate,
    }));
  };

  //`${newdate.$y}-${newdate.$M}-${newdate.$D} ${newdate.$H}:${newdate.$m}:${newdate.$s}`
  const handleDeletePromotion = () => {
    // eslint-disable-next-line no-restricted-globals
    const decision = confirm(`¿Esta seguro que desea eliminar la promoción?`);
    if (decision) {
      fetch(`${API_URL}campaing/delete-promotion/${idPromotion}`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.isSuccess) {
            alert(data.message);
            handleCloseModal();
          } else {
            alert(data.message);
            handleCloseModal();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  function formatearFecha(objetoFecha) {
    // Función para agregar ceros a la izquierda si es necesario
    const agregarCeros = (valor) => (valor < 10 ? `0${valor}` : valor);

    const año = objetoFecha.$y;
    const mes = agregarCeros(objetoFecha.$M + 1);
    const dia = agregarCeros(objetoFecha.$D);
    const hora = agregarCeros(objetoFecha.$H);
    const minuto = agregarCeros(objetoFecha.$m);

    // Formatear la fecha y hora
    const fechaFormateada = `${año}-${mes}-${dia}T${hora}:${minuto}`;

    return fechaFormateada;
  }
  const handleCreateUser = () => {
    // Verificar si algún campo está vacío
    if (
      formValues.Start === "" ||
      formValues.End === "" ||
      formValues.Action === ""
    ) {
      alert("Por favor, complete todos los campos.");
    } else {
      if (editMode) {
        const Start = formValues.Start;
        const End = formValues.End;
        console.log(formatearFecha(Start));
        console.log(formatearFecha(End));
        fetch(`${API_URL}campaing/update-promotion`, {
          method: "PUT",
          headers: {
            "Content-Type": "application/json", // Especificamos que el cuerpo es JSON
          },
          body: JSON.stringify({
            Start: formatearFecha(Start),
            End: formatearFecha(End),
            Action: formValues.Action,
            id: idPromotion,
          }),
        })
          .then((response) => response.json())
          .then((data) => {
            if (data.isSuccess) {
              alert(data.message);
              handleCloseModal();
            } else {
              alert(data.error);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        const Start = formValues.Start;
        const End = formValues.End;
        fetch(`${API_URL}campaing/create-promotion`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json", // Especificamos que el cuerpo es JSON
          },
          body: JSON.stringify({
            UserId: usuario.UserId,
            FileId: usuario.FileId,
            Start: formatearFecha(Start),
            End: formatearFecha(End),
            Action: formValues.Action,
          }),
        })
          .then((response) => response.json())
          .then((data) => {
            if (data.isSuccess) {
              fetch(`${API_URL}campaing/deactivate-file`, {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                },
                body: JSON.stringify({
                  userId: usuario.UserId,
                  fileId: usuario.FileId,
                  value: 0,
                }),
              })
                .then((response) => response.json())
                .then((data) => {
                  console.log(data);
                })
                .catch((err) => {
                  console.log(err);
                });
              alert(data.message);
              handleCloseModal();
            } else {
              alert(data.message);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    }
  };
  return (
    <Box
      sx={{
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        backgroundColor: colors.primary[800],
        boxShadow: 24,
        p: 4,
        borderRadius: "8px",
        minWidth: "400px",
      }}
    >
      <Typography variant="h4" mb={2}>
        {editMode ? "Editar Promoción" : "Crear Promoción"}
      </Typography>
      <div style={{ margin: "20px 0px" }}>
        <LocalizationProvider
          dateAdapter={AdapterDayjs}
          locale={esLocale}
          adapterLocale="es"
        >
          <DemoContainer components={["MobileDateTimePicker"]}>
            <DemoItem label="Fecha de inicio">
              <MobileDateTimePicker
                value={formValues.Start}
                onChange={(newValue) => handleChangeDate("Start", newValue)}
                format="DD/MM/YYYY HH:mm"
                localeText={{
                  clearButtonLabel: "Vaciar",
                  todayButtonLabel: "Hoy",
                  okButtonLabel: "Guardar",
                  cancelButtonLabel: "Cancelar",
                  toolbarTitle: "Selecciona fecha y hora",
                }}
                slotProps={{
                  color: "#fcfcfc",

                  clearIcon: {
                    // Puedes agregar propiedades de estilo para el icono de limpiar aquí
                    style: {
                      color: "#000000",
                    },
                  },

                  // Puedes agregar más componentes y estilos según sea necesario
                }}
              />
            </DemoItem>
          </DemoContainer>
        </LocalizationProvider>
        <LocalizationProvider
          dateAdapter={AdapterDayjs}
          locale={esLocale}
          adapterLocale="es"
        >
          <DemoContainer components={["MobileDateTimePicker"]}>
            <DemoItem label="Fecha de fin">
              <MobileDateTimePicker
                value={formValues.End}
                onChange={(newValue) => handleChangeDate("End", newValue)}
                format="DD/MM/YYYY HH:mm"
                localeText={{
                  clearButtonLabel: "Vaciar",
                  todayButtonLabel: "Hoy",
                  okButtonLabel: "Guardar",
                  cancelButtonLabel: "Cancelar",
                  toolbarTitle: "Selecciona fecha y hora",
                }}
              />
            </DemoItem>
          </DemoContainer>
        </LocalizationProvider>
      </div>
      <Box>
        <InputLabel
          sx={{
            fontSize: "15px",
          }}
        >
          Al finalizar promoción:
        </InputLabel>
        <FormControl fullWidth>
          <Select
            id="Action"
            name="Action"
            value={formValues.Action}
            onChange={handleChange}
            variant="outlined"
          >
            <MenuItem value={0}>Desactivar Archivo</MenuItem>
            <MenuItem value={1}>Eliminar Archivo</MenuItem>
            <MenuItem value={2}>No hacer nada</MenuItem>
          </Select>
        </FormControl>
      </Box>
      {/* <FormGroup>
        <FormControlLabel
          control={
            <Checkbox
              checked={formValues.IsPro}
              onChange={handleChange}
              name="IsPro"
            />
          }
          label="Pro"
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={formValues.IsActive}
              onChange={handleChange}
              name="IsActive"
            />
          }
          label="Activo"
        />
      </FormGroup> */}
      <Box display="flex" justifyContent="space-around" mt={3}>
        {/* Botón para imprimir los datos */}
        <Button
          variant="contained"
          color="secondary"
          onClick={handleCreateUser}
        >
          {editMode ? "Actualizar" : "Guardar"}
        </Button>
        {/* Botón para cerrar el modal */}
        <Button variant="contained" onClick={handleCloseModal}>
          Cerrar
        </Button>
        {editMode && (
          <Button
            variant="contained"
            color="error"
            onClick={handleDeletePromotion}
          >
            Eliminar
          </Button>
        )}
      </Box>
    </Box>
  );
};

export default ModalPromotion;
