import { Box, Typography, useTheme, IconButton } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";
import { Image, Videocam } from "@mui/icons-material";
import { DataGrid, esES } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import { useState, useEffect } from "react";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { API_URL } from "../../data/AppConstants";
import UserCard from "./UserCard";
import Loader from "../global/Loader";
import { Toaster, toast } from "sonner";
import CampaignIcon from "@mui/icons-material/Campaign";
import Tooltip from "@mui/material/Tooltip";
import { useAuth } from "../../context/AuthContext";
import ModalPromotion from "../../components/ModalPromotion";
import Modal from "@mui/material/Modal";

const Files = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [usuarios, setUsuarios] = useState({});
  const [files, setFiles] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [userToEdit, setUserToEdit] = useState([]);
  const [shouldOpenModal, setShouldOpenModal] = useState(false);
  const { loggedUserId, isMobile } = useAuth();

  useEffect(() => {
    if (shouldOpenModal) {
      handleOpenModal();
      setShouldOpenModal(false);
    }
  }, [shouldOpenModal, userToEdit]);

  const handleDeactivate = (id, userId, active) => {
    fetch(`${API_URL}campaing/deactivate-file`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        userId: userId,
        fileId: id,
        value: active === 0 ? 1 : 0,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.ok) {
          toast.success(data.message);
          fetchFiles();
        } else {
          toast.error(data.message);
        }
      })
      .catch((err) => {
        toast.error(err.error);
        console.log(err);
      });
  };

  const handleAddPromo = (id, userId) => {
    // const userFilter = usuarios.filter((user) => user.id === id);
    setUserToEdit({ ...usuarios, UserId: userId, FileId: id });
    setShouldOpenModal(true);
  };

  const fetchUsersData = () => {
    fetch(`${API_URL}user/getUserData`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json", // Especifica el tipo de contenido como JSON
        // Otros encabezados si es necesario
      },
      body: JSON.stringify({ userId: loggedUserId }),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        if (data.isSuccess) {
          setUsuarios(data.result);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const fetchFiles = () => {
    fetch(`${API_URL}user/getFiles`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ UserId: loggedUserId }),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        if (data.ok) {
          setFiles(data.files);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleDeleteFile = (id, userId) => {
    const file = files.filter((file) => file.id === id)[0];
    // eslint-disable-next-line no-restricted-globals
    const decision = confirm(
      `Esta seguro que desea eliminar el archivo ${file.Name}`
    );
    if (decision) {
      fetch(`${API_URL}campaing/delete-file`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ userId: userId, fileId: id }),
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.ok) {
            toast.success(data.message);
            fetchFiles();
          } else {
            toast.error(data.message);
          }
        })
        .catch((err) => {
          toast.error(err.error);
          console.log(err);
        });
    }
  };

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    handleFetchData();
  };

  const syncFiles = () => {
    setIsLoading(true);
    fetch(`${API_URL}campaing/sync-files`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        userId: loggedUserId,
        folderId: usuarios.user.Folder,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        if (data.ok) {
          fetchFiles();
          setIsLoading(false);
        } else {
          console.log(data.error);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const handleFetchData = () => {
    fetchUsersData();
    fetchFiles();
  };
  useEffect(() => {
    fetchUsersData();
    fetchFiles();
  }, [loggedUserId]);

  const columns = [
    {
      field: "TypeAd",
      headerName: "",
      flex: isMobile ? 1 : "none",
      renderCell: ({ row: { TypeAd } }) => {
        return (
          <Box
            width="60%"
            m="0 auto"
            p="5px"
            display="flex"
            justifyContent="center"
            backgroundColor="transparent"
            borderRadius="4px"
          >
            {TypeAd === 1 ? (
              <Image fontSize="large" />
            ) : (
              <Videocam fontSize="large" />
            )}
          </Box>
        );
      },
    },
    {
      field: "id",
      headerName: "Id",
      flex: isMobile ? "none" : 1,
      headerAlign: "left",
      align: "left",
    },
    {
      field: "Name",
      headerName: "Nombre",
      flex: isMobile ? "none" : 1,
    },
    {
      field: "IsActive",
      headerName: "Activo",
      headerAlign: "center",
      flex: isMobile ? "none" : 1,
      renderCell: ({ row: { IsActive } }) => {
        return (
          <Box
            width="60%"
            m="0 auto"
            p="5px"
            display="flex"
            justifyContent="center"
            backgroundColor="transparent"
            borderRadius="4px"
          >
            {IsActive ? (
              <CheckIcon style={{ color: "green" }} />
            ) : (
              <ClearIcon style={{ color: "red" }} />
            )}
            <Typography color={colors.grey[100]} sx={{ ml: "5px" }}>
              {IsActive ? "Activo" : "Inactivo"}
            </Typography>
          </Box>
        );
      },
    },
    {
      field: "actions",
      headerName: "Acciones",
      width: 160,
      renderCell: ({ row }) => {
        return (
          <Box display="flex" justifyContent="center" alignItems="center">
            <Tooltip
              title={
                row.IsActive === 0 ? "Activar archivo." : "Desactivar archivo."
              }
            >
              <IconButton
                onClick={() =>
                  handleDeactivate(row.id, row.UserId, row.IsActive)
                }
                color="secondary"
                aria-label="Editar"
              >
                <EditIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Eliminar archivo.">
              <IconButton
                onClick={() => handleDeleteFile(row.id, row.UserId)}
                color="secondary"
                aria-label="Eliminar"
              >
                <DeleteIcon />
              </IconButton>
            </Tooltip>
            {usuarios?.user?.IsPro === 1 && (
              <Tooltip title="Agregar Promoción.">
                <IconButton
                  onClick={() => handleAddPromo(row.id, row.UserId)}
                  color="secondary"
                  aria-label="Eliminar"
                >
                  <CampaignIcon />
                </IconButton>
              </Tooltip>
            )}
          </Box>
        );
      },
    },
  ];

  return (
    <Box m="20px" display="flex" flexDirection="column" flex="1">
      <Toaster richColors />
      <Header title="Archivos" subtitle="" showButton={false} />
      <UserCard
        user={usuarios.user}
        handleSync={syncFiles}
        handle={handleFetchData}
      />
      {isLoading ? (
        <Loader />
      ) : (
        <Box
          m="40px 0 0 0"
          height="75vh"
          sx={{
            "& .MuiDataGrid-root": {
              border: "none",
            },
            "& .MuiDataGrid-cell": {
              borderBottom: "none",
            },
            "& .name-column--cell": {
              color: colors.greenAccent[300],
            },
            "& .MuiDataGrid-columnHeaders": {
              backgroundColor: colors.blueAccent[700],
              borderBottom: "none",
            },
            "& .MuiDataGrid-virtualScroller": {
              backgroundColor: colors.primary[400],
            },
            "& .MuiDataGrid-footerContainer": {
              borderTop: "none",
              backgroundColor: colors.blueAccent[700],
            },
            "& .MuiCheckbox-root": {
              color: `${colors.greenAccent[200]} !important`,
            },
          }}
        >
          <DataGrid
            rows={files}
            columns={columns}
            localeText={esES.components.MuiDataGrid.defaultProps.localeText}
          />
        </Box>
      )}
      <Modal open={isModalOpen} onClose={handleCloseModal}>
        <div>
          <ModalPromotion
            handleCloseModal={handleCloseModal}
            usuario={userToEdit}
          />
        </div>
      </Modal>
    </Box>
  );
};

export default Files;
