import { Box, Button, TextField } from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import useMediaQuery from "@mui/material/useMediaQuery";
import Header from "../../components/Header";
import { useState } from "react";

const Form = () => {
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const [isEditing, setIsEditing] = useState(true);

  const handleFormSubmit = (values) => {
    if (isEditing) {
      setIsEditing(false);
    } else {
      setIsEditing(true);
    }
  };

  return (
    <Box m="20px">
      <Header
        title="CONFIGURACIÓN"
        subtitle="Si vas a cambiar de usuario en google cloud, modifica tus credenciales"
      />

      <Formik
        onSubmit={handleFormSubmit}
        initialValues={initialValues}
        validationSchema={checkoutSchema}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
        }) => (
          <form onSubmit={handleSubmit}>
            <Box
              display="grid"
              gap="30px"
              gridTemplateColumns="repeat(4, minmax(0, 1fr))"
              sx={{
                "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
              }}
            >
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Cuenta"
                onBlur={handleBlur}
                disabled={isEditing}
                onChange={handleChange}
                value={values.email}
                name="email"
                error={!!touched.email && !!errors.email}
                helperText={touched.email && errors.email}
                sx={{ gridColumn: "span 4" }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Client ID"
                disabled={isEditing}
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.clientID}
                name="clientID"
                error={!!touched.clientID && !!errors.clientID}
                helperText={touched.clientID && errors.clientID}
                sx={{ gridColumn: "span 4" }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Client Secret"
                disabled={isEditing}
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.clientSecret}
                name="clientSecret"
                error={!!touched.clientSecret && !!errors.clientSecret}
                helperText={touched.clientSecret && errors.clientSecret}
                sx={{ gridColumn: "span 4" }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Refresh Token"
                disabled={isEditing}
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.refreshToken}
                name="refreshToken"
                error={!!touched.refreshToken && !!errors.refreshToken}
                helperText={touched.refreshToken && errors.refreshToken}
                sx={{ gridColumn: "span 4" }}
              />
            </Box>
            <Box display="flex" justifyContent="end" mt="20px">
              <Button type="submit" color="secondary" variant="contained">
                {isEditing ? "Editar" : "Guardar"}
              </Button>
            </Box>
          </form>
        )}
      </Formik>
    </Box>
  );
};


const checkoutSchema = yup.object().shape({
  email: yup.string().email("invalid email").required("required"),
  clientID: yup.string().required("required"),
  clientSecret: yup.string().required("required"),
  refreshToken: yup.string().required("required"),
});
const initialValues = {
  email: "pruebaparachcast@gmail.com",
  clientID:
    "412226113433-2hij3ka15a04etsb9gp0kjeq94cdfu9m.apps.googleusercontent.com",
  clientSecret: "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",
  refreshToken:
    "1//04MZhEowGFo5oCgYIARAAGAQSNwF-L9Ir75pD9cdrLfkozaa-PRE_Og3VbQGr0X2r3d2XKnXGFbyjZwlr3xZwzzTEEjEpUP-y3gE",
};

export default Form;
